import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getParamFromUrl,
  generateRulesFromUrl,
  generateValues,
  generateRawQueryLanguage,
} from "../../util/helpers";
import request from "../../util/request";
import ApiInfo from "./ApiInfo";
import RawQuery from "../organisms/RawQuery";
import QueryResults from "../organisms/QueryResults";
import QueryBuilderForm from "./QueryBuilderForm";
import SimpleQueryBuilderForm from "./SimpleQueryBuilderForm";
import getApiMetadata from "../../constants/nameConstants";

const QueryBuilderLayout = ({
  loaded,
  setLoaded,
  fields,
  settings,
  curlEndpoint,
  isBlogSearchApi,
  simpleBuilder,
  apiName,
}) => {
  let location = useLocation();
  let navigate = useNavigate();

  const apiEndpoint = getApiMetadata(apiName).apiEndpoint;
  const urlQuery = getParamFromUrl(location.search, "query");

  const formValuesFromUrl =
    urlQuery && JSON.parse(decodeURIComponent(urlQuery));

  const defaultValues = formValuesFromUrl
    ? { ...settings.defaultValues, ...formValuesFromUrl }
    : settings.defaultValues;

  const resultId = "results-paper";

  const [rules, setRules] = useState([simpleBuilder ? "query" : "all"]);
  const [rawResults, setRawResults] = useState();
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [queryExecuting, setQueryExecuting] = useState(false);
  const [error, setError] = useState();
  const [formValues, setFormValues] = useState();
  const [apiData, setApiData] = useState({
    enabled: false,
    numberOfRequests: null,
    languages: null,
    locations: null,
  });

  useEffect(() => {
    const newRules = generateRulesFromUrl(formValuesFromUrl);
    formValuesFromUrl && setFormValues(formValuesFromUrl);
    formValuesFromUrl && newRules.length && setRules(newRules);
  }, []);

  useEffect(() => {
    const urlQuery = encodeURIComponent(
      JSON.stringify(formValues) ? JSON.stringify(formValues) : ""
    );

    urlQuery && navigate(`?tab=builder&query=${urlQuery}`);
  }, [formValues, rules]);

  useEffect(() => {
    setLoaded(false);
    request.get("/api/v1/proxy/products").then((res) => {
      setApiData({
        enabled: res.data[apiName].enabled,
        numberOfRequests: res.data[apiName].number_of_requests_current_period,
        requestLimit: res.data[apiName].number_of_requests_limit,
        isSoftLimit: res.data[apiName].is_soft_request_limit,
        isTrial: res.data[apiName].is_trial,
        languages: res.data[apiName].metadata.languages,
        locations: res.data[apiName].metadata.locations,
        enabledLanguages: res.data[apiName].metadata.languages,
        enabledLocations: res.data[apiName].metadata.locations,
      });
      setLoaded(true);
    });
  }, []);

  const addRule = (newRule) => {
    const isArray = Array.isArray(newRule);
    isArray ? setRules([...rules, ...newRule]) : setRules([...rules, newRule]);

    if (isArray) {
      const newValues = {};
      newRule.forEach((item) => {
        const itemIsArray = Array.isArray(defaultValues[item]);
        if (itemIsArray) {
          if (defaultValues[item].length) newValues[item] = defaultValues[item];
        } else if (defaultValues[item]) {
          newValues[item] = defaultValues[item];
        }
      });
      setFormValues((values) => ({
        ...values,
        ...newValues,
      }));
    } else {
      defaultValues[newRule] &&
        setFormValues((values) => ({
          ...values,
          [newRule]: defaultValues[newRule],
        }));
    }
  };

  const removeRule = (rulesToRemove) => {
    const newRules = rules.filter((item) => {
      if (!rulesToRemove.includes(item)) {
        return item;
      } else {
        return null;
      }
    });
    const newValues = formValues;

    newValues &&
      Object.keys(newValues).forEach((item) => {
        if (rulesToRemove.includes(item)) {
          delete newValues[item];
        }
      });
    setRules(newRules);
    setFormValues(newValues);
  };

  const handleChange = (name, value) => {
    setFormValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmitQuery = () => {
    setRawResults();
    setQueryExecuted(false);
    setQueryExecuting(true);
    setError();

    let allFormValues = formValues;
    let allRules = rules;
    if (settings.additionalHiddenQueryValues !== undefined) {
      allFormValues = {
        ...allFormValues,
        ...settings.additionalHiddenQueryValues,
      };
      allRules = [
        ...rules,
        ...Object.keys(settings.additionalHiddenQueryValues),
      ];
    }

    const requestParams = isBlogSearchApi
      ? {
          blog_search: JSON.stringify({
            q: generateRawQueryLanguage(formValues),
          }),
        }
      : {
          [apiEndpoint]: JSON.stringify(
            generateValues(allFormValues, allRules, settings.querySettings)
          ),
        };
    request({
      method: "get",
      url: `/api/v1/proxy/${apiEndpoint}`,
      params: requestParams,
    }).then((res) => {
      if (res.data.status && res.data.error) {
        setQueryExecuting(false);
        setError(res.data);
      } else if (res.data.warnings?.length) {
        setQueryExecuting(false);
        setError({
          Message: res.data.warnings.map((item) => item.message).join(" "),
        });
      } else {
        setQueryExecuted(true);
        setRawResults(res.data);
        setQueryExecuting(false);
        const results = document.getElementById(resultId);
        results && results.scrollIntoView({ behavior: "smooth" }, true);
      }
    });
  };

  return (
    <Box
      sx={{
        p: { xs: 1, md: 3 },
        pt: { xs: 2, md: 3 },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
      gap={2}
    >
      {loaded && (
        <>
          <Box
            sx={{
              width: { md: "65%", xl: "50%" },
              maxWidth: "1000px",
              display: "flex",
              flexDirection: "column",
            }}
            gap={2}
          >
            {simpleBuilder ? (
              <SimpleQueryBuilderForm
                handleChange={handleChange}
                apiData={apiData}
                queryName={apiName}
                fields={fields}
                handleSubmitQuery={handleSubmitQuery}
                defaultValues={defaultValues}
                queryExecuting={queryExecuting}
              />
            ) : (
              <QueryBuilderForm
                rules={rules}
                addRule={addRule}
                queryName={apiName}
                apiData={apiData}
                defaultValues={defaultValues}
                handleChange={handleChange}
                handleSubmitQuery={handleSubmitQuery}
                removeRule={removeRule}
                fields={fields}
                initialDefaultValues={settings.defaultValues}
                queryExecuting={queryExecuting}
              />
            )}
            <RawQuery
              querySettings={settings.querySettings}
              rules={rules}
              formValues={formValues}
              curlEndpoint={curlEndpoint}
              isBlogSearchApi={isBlogSearchApi}
              simpleBuilder={simpleBuilder}
            />
            <QueryResults
              queryExecuting={queryExecuting}
              queryExecuted={queryExecuted}
              rawResults={rawResults}
              apiData={apiData}
              setApiData={setApiData}
              resultId={resultId}
              isBlogSearchApi={isBlogSearchApi}
              error={error}
              apiName={apiName}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "35%", xl: "25%" },
              maxWidth: { md: "380px" },
            }}
          >
            <ApiInfo apiName={apiName} apiData={apiData} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default QueryBuilderLayout;
