const errorCode = {
  400: "Invalid query.",
  401: "Invalid API key.",
  429: "Rate Limit Exceeded.",
  500: "Internal Server Error.",
  503: "Service Unavailable.",
  40001: "Invalid parameters",
  40002: "Invalid query",
  40003: "Invalid query",
  40101: "Unauthorized",
  40201: "Access to language(s) denied",
  40401: "Not Found",
  50001:
    "Internal Server Error. Unexpected conditions were encountered, indicating a server-side bug.",
  50301: "Service Unavailable. Retry later.",
};

const errorCodeDescription = {
  400: "This happens if the query is missing or invalid.",
  401: "This happens if the API key is missing or invalid.",
  429: "This indicates too many requests per rate limit window, the client should back off and reduce the requests per window.",
  500: "Unexpected conditions were encountered, indicating a server-side bug.",
  503: "Indicates temporary problem, please try again.",
};

export { errorCode, errorCodeDescription };
