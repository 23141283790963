import React from "react";
import { Box, Typography } from "@mui/material";
import Icon from "../atoms/Icon";
import { errorCode, errorCodeDescription } from "../../util/phrases";

const ResultsError = ({ error }) => {
  const message = error.Message;
  const code = error.code || error.status;
  const errorText = error.error || error.message;

  const fieldToFilterMap = {
    "'all'": "Match All",
    "'any'": "Match Any",
    "'none'": "Match None",
    "'domains'": "Search Domains",
    "'exclude_domains'": "Exclude Domains",
    "'url'": "URL",
    "'site_id'": "Site id",
    "'article_ids'": "Article id",
    "'document_id'": "Document ID",
    "'item_url'": "Item URL",
    "'item_name'": "Item Name",
    "'item_address'": "Item Address",
    "'domain'": "Search Domain",
    "'exclude_domain'": "Exclude Domain",
    "'authors'": "Author ID",
    "'post_owners'": "Post Owner ID",
    "'published_time.since'": "Published Since",
    "'published_time.until'": "Published Until",
    "'inserted_time.since'": "Inserted Since",
    "'inserted_time.until'": "Inserted Until",
    "'since'": "Since",
    "'until'": "Until",
    "'size'": "Number of Results",
    "'offset'": "Offset",
  };

  const translateApiFieldNamesToFilterNames = (errorMessage) => {
    let translatedError = errorMessage;
    for (const field in fieldToFilterMap) {
      translatedError = translatedError.replace(field, fieldToFilterMap[field]);
    }
    return translatedError;
  };

  return (
    <Box>
      <Box
        sx={{
          px: 2,
          py: 3,
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          svg: {
            width: 24,
            height: 24,
            flex: "0 0 24px",
          },
        }}
      >
        <Icon name="infoError" />
        <Typography color="error" ml={1} fontWeight={500}>
          {message ? (
            message
          ) : (
            <>
              {errorCode[code]} {`[Error ${code}]`}
            </>
          )}
        </Typography>
      </Box>
      {!message && (
        <Box px={2} py={3}>
          <Typography>
            {errorText
              ? translateApiFieldNamesToFilterNames(errorText)
              : errorCodeDescription[code]}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ResultsError;
