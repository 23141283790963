import { subDays, zeroMinutesSeconds } from "../../util/helpers";

export default {
  defaultValues: {
    all: [],
    any: [],
    none: [],
    url: "",
    languages: [],
    locations: [],
    since: zeroMinutesSeconds(subDays(new Date(), 7)),
    until: zeroMinutesSeconds(new Date()),
    site_id: "",
    article_ids: [],
    size: 10,
    article_readership: false,
    article_topics_and_entities: false,
  },
  querySettings: {
    dateInputs: ["since", "until"],
    selectInputs: ["languages", "locations"],
    singleDropdown: [],
    replaceKeyName: [],
    nestedObjectFields: { since: "timestamp", until: "timestamp" },
    numberInputs: ["size", "site_id", "article_ids"],
  },
  languages: [
    { label: "Afar", value: "aa" },
    { label: "Abkhazian", value: "ab" },
    { label: "Afrikaans", value: "af" },
    { label: "Akan", value: "ak" },
    { label: "Albanian", value: "sq" },
    { label: "Amharic", value: "am" },
    { label: "Arabic", value: "ar" },
    { label: "Aragonese", value: "an" },
    { label: "Armenian", value: "hy" },
    { label: "Assamese", value: "as" },
    { label: "Avaric", value: "av" },
    { label: "Avestan", value: "ae" },
    { label: "Aymara", value: "ay" },
    { label: "Azerbaijani", value: "az" },
    { label: "Bashkir", value: "ba" },
    { label: "Bambara", value: "bm" },
    { label: "Basque", value: "eu" },
    { label: "Belarusian", value: "be" },
    { label: "Bengali", value: "bn" },
    { label: "Bihari languages", value: "bh" },
    { label: "Bislama", value: "bi" },
    { label: "Bosnian", value: "bs" },
    { label: "Breton", value: "br" },
    { label: "Bulgarian", value: "bg" },
    { label: "Burmese", value: "my" },
    { label: "Catalan", value: "ca" },
    { label: "Chamorro", value: "ch" },
    { label: "Chechen", value: "ce" },
    { label: "Chinese", value: "zh" },
    { label: "Church Slavic", value: "cu" },
    { label: "Chuvash", value: "cv" },
    { label: "Cornish", value: "kw" },
    { label: "Corsican", value: "co" },
    { label: "Cree", value: "cr" },
    { label: "Czech", value: "cs" },
    { label: "Danish", value: "da" },
    { label: "Divehi", value: "dv" },
    { label: "Dutch", value: "nl" },
    { label: "Dzongkha", value: "dz" },
    { label: "English", value: "en" },
    { label: "Esperanto", value: "eo" },
    { label: "Estonian", value: "et" },
    { label: "Ewe", value: "ee" },
    { label: "Faroese", value: "fo" },
    { label: "Fijian", value: "fj" },
    { label: "Finnish", value: "fi" },
    { label: "French", value: "fr" },
    { label: "Western Frisian", value: "fy" },
    { label: "Fulah", value: "ff" },
    { label: "Georgian", value: "ka" },
    { label: "German", value: "de" },
    { label: "Gaelic", value: "gd" },
    { label: "Irish", value: "ga" },
    { label: "Galician", value: "gl" },
    { label: "Manx", value: "gv" },
    { label: "Greek, Modern (1453-)", value: "el" },
    { label: "Guarani", value: "gn" },
    { label: "Gujarati", value: "gu" },
    { label: "Haitian", value: "ht" },
    { label: "Hausa", value: "ha" },
    { label: "Hebrew", value: "he" },
    { label: "Herero", value: "hz" },
    { label: "Hindi", value: "hi" },
    { label: "Hiri Motu", value: "ho" },
    { label: "Croatian", value: "hr" },
    { label: "Hungarian", value: "hu" },
    { label: "Igbo", value: "ig" },
    { label: "Icelandic", value: "is" },
    { label: "Ido", value: "io" },
    { label: "Sichuan Yi", value: "ii" },
    { label: "Inuktitut", value: "iu" },
    { label: "Interlingue", value: "ie" },
    {
      label: "Interlingua (International Auxiliary Language Association)",
      value: "ia",
    },
    { label: "Indonesian", value: "id" },
    { label: "Inupiaq", value: "ik" },
    { label: "Italian", value: "it" },
    { label: "Javanese", value: "jv" },
    { label: "Japanese", value: "ja" },
    { label: "Kalaallisut", value: "kl" },
    { label: "Kannada", value: "kn" },
    { label: "Kashmiri", value: "ks" },
    { label: "Kanuri", value: "kr" },
    { label: "Kazakh", value: "kk" },
    { label: "Central Khmer", value: "km" },
    { label: "Kikuyu", value: "ki" },
    { label: "Kinyarwanda", value: "rw" },
    { label: "Kirghiz", value: "ky" },
    { label: "Komi", value: "kv" },
    { label: "Kongo", value: "kg" },
    { label: "Korean", value: "ko" },
    { label: "Kuanyama", value: "kj" },
    { label: "Kurdish", value: "ku" },
    { label: "Lao", value: "lo" },
    { label: "Latin", value: "la" },
    { label: "Latvian", value: "lv" },
    { label: "Limburgan", value: "li" },
    { label: "Lingala", value: "ln" },
    { label: "Lithuanian", value: "lt" },
    { label: "Luxembourgish", value: "lb" },
    { label: "Luba-Katanga", value: "lu" },
    { label: "Ganda", value: "lg" },
    { label: "Macedonian", value: "mk" },
    { label: "Marshallese", value: "mh" },
    { label: "Malayalam", value: "ml" },
    { label: "Maori", value: "mi" },
    { label: "Marathi", value: "mr" },
    { label: "Malay", value: "ms" },
    { label: "Malagasy", value: "mg" },
    { label: "Maltese", value: "mt" },
    { label: "Mongolian", value: "mn" },
    { label: "Nauru", value: "na" },
    { label: "Navajo", value: "nv" },
    { label: "Ndebele, South", value: "nr" },
    { label: "Ndebele, North", value: "nd" },
    { label: "Ndonga", value: "ng" },
    { label: "Nepali", value: "ne" },
    { label: "Norwegian Nynorsk", value: "nn" },
    { label: "Bokmål, Norwegian", value: "nb" },
    { label: "Norwegian", value: "no" },
    { label: "Chichewa", value: "ny" },
    { label: "Occitan (post 1500)", value: "oc" },
    { label: "Ojibwa", value: "oj" },
    { label: "Oriya", value: "or" },
    { label: "Oromo", value: "om" },
    { label: "Ossetian", value: "os" },
    { label: "Panjabi", value: "pa" },
    { label: "Persian", value: "fa" },
    { label: "Pali", value: "pi" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese", value: "pt" },
    { label: "Pushto", value: "ps" },
    { label: "Quechua", value: "qu" },
    { label: "Romansh", value: "rm" },
    { label: "Romanian", value: "ro" },
    { label: "Rundi", value: "rn" },
    { label: "Russian", value: "ru" },
    { label: "Sango", value: "sg" },
    { label: "Sanskrit", value: "sa" },
    { label: "Sinhala", value: "si" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Northern Sami", value: "se" },
    { label: "Samoan", value: "sm" },
    { label: "Shona", value: "sn" },
    { label: "Sindhi", value: "sd" },
    { label: "Somali", value: "so" },
    { label: "Sotho, Southern", value: "st" },
    { label: "Spanish", value: "es" },
    { label: "Sardinian", value: "sc" },
    { label: "Serbian", value: "sr" },
    { label: "Swati", value: "ss" },
    { label: "Sundanese", value: "su" },
    { label: "Swahili", value: "sw" },
    { label: "Swedish", value: "sv" },
    { label: "Tahitian", value: "ty" },
    { label: "Tamil", value: "ta" },
    { label: "Tatar", value: "tt" },
    { label: "Telugu", value: "te" },
    { label: "Tajik", value: "tg" },
    { label: "Tagalog", value: "tl" },
    { label: "Thai", value: "th" },
    { label: "Tibetan", value: "bo" },
    { label: "Tigrinya", value: "ti" },
    { label: "Tonga (Tonga Islands)", value: "to" },
    { label: "Tswana", value: "tn" },
    { label: "Tsonga", value: "ts" },
    { label: "Turkmen", value: "tk" },
    { label: "Turkish", value: "tr" },
    { label: "Twi", value: "tw" },
    { label: "Uighur", value: "ug" },
    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Uzbek", value: "uz" },
    { label: "Venda", value: "ve" },
    { label: "Vietnamese", value: "vi" },
    { label: "Volapük", value: "vo" },
    { label: "Welsh", value: "cy" },
    { label: "Walloon", value: "wa" },
    { label: "Wolof", value: "wo" },
    { label: "Xhosa", value: "xh" },
    { label: "Yiddish", value: "yi" },
    { label: "Yoruba", value: "yo" },
    { label: "Zhuang", value: "za" },
    { label: "Zulu", value: "zu" },
  ],
  locations: [
    { label: "Andorra", value: "ad" },
    { label: "United Arab Emirates", value: "ae" },
    { label: "Afghanistan", value: "af" },
    { label: "Antigua and Barbuda", value: "ag" },
    { label: "Anguilla", value: "ai" },
    { label: "Albania", value: "al" },
    { label: "Armenia", value: "am" },
    { label: "Netherlands Antilles", value: "an" },
    { label: "Angola", value: "ao" },
    { label: "Argentina", value: "ar" },
    { label: "American Samoa", value: "as" },
    { label: "Austria", value: "at" },
    { label: "Australia", value: "au" },
    { label: "Aruba", value: "aw" },
    { label: "Azerbaidjan", value: "az" },
    { label: "Bosnia-Herzegovina", value: "ba" },
    { label: "Barbados", value: "bb" },
    { label: "Bangladesh", value: "bd" },
    { label: "Belgium", value: "be" },
    { label: "Burkina Faso", value: "bf" },
    { label: "Bulgaria", value: "bg" },
    { label: "Bahrain", value: "bh" },
    { label: "Burundi", value: "bi" },
    { label: "Benin", value: "bj" },
    { label: "Bermuda", value: "bm" },
    { label: "Brunei Darussalam", value: "bn" },
    { label: "Bolivia", value: "bo" },
    { label: "Brazil", value: "br" },
    { label: "Bahamas", value: "bs" },
    { label: "Bhutan", value: "bt" },
    { label: "Botswana", value: "bw" },
    { label: "Belarus", value: "by" },
    { label: "Belize", value: "bz" },
    { label: "Canada", value: "ca" },
    { label: "Cocos (Keeling) Islands", value: "cc" },
    { label: "The Democratic Republic of the Congo", value: "cd" },
    { label: "Central African Republic", value: "cf" },
    { label: "Congo", value: "cg" },
    { label: "Switzerland", value: "ch" },
    { label: "Côte d’Ivoire (Ivory Coast)", value: "ci" },
    { label: "Cook Islands", value: "ck" },
    { label: "Chile", value: "cl" },
    { label: "Cameroon", value: "cm" },
    { label: "China", value: "cn" },
    { label: "Colombia", value: "co" },
    { label: "Costa Rica", value: "cr" },
    { label: "Cuba", value: "cu" },
    { label: "Cape Verde", value: "cv" },
    { label: "Christmas Island", value: "cx" },
    { label: "Cyprus", value: "cy" },
    { label: "Czech Republic", value: "cz" },
    { label: "Germany", value: "de" },
    { label: "Djibouti", value: "dj" },
    { label: "Denmark", value: "dk" },
    { label: "Dominica", value: "dm" },
    { label: "Dominican Republic", value: "do" },
    { label: "Algeria", value: "dz" },
    { label: "Ecuador", value: "ec" },
    { label: "Estonia", value: "ee" },
    { label: "Egypt", value: "eg" },
    { label: "Western Sahara", value: "eh" },
    { label: "Eritrea", value: "er" },
    { label: "Spain", value: "es" },
    { label: "Ethiopia", value: "et" },
    { label: "Finland", value: "fi" },
    { label: "Fiji", value: "fj" },
    { label: "Falkland Islands", value: "fk" },
    { label: "Micronesia", value: "fm" },
    { label: "Faroe Islands", value: "fo" },
    { label: "France", value: "fr" },
    { label: "Gabon", value: "ga" },
    { label: "United Kingdom", value: "gb" },
    { label: "Grenada", value: "gd" },
    { label: "Georgia", value: "ge" },
    { label: "French Guyana", value: "gf" },
    { label: "Ghana", value: "gh" },
    { label: "Gibraltar", value: "gi" },
    { label: "Greenland", value: "gl" },
    { label: "Gambia", value: "gm" },
    { label: "Guinea", value: "gn" },
    { label: "Guadeloupe (French)", value: "gp" },
    { label: "Equatorial Guinea", value: "gq" },
    { label: "Greece", value: "gr" },
    { label: "Guatemala", value: "gt" },
    { label: "Guam (USA)", value: "gu" },
    { label: "Guinea Bissau", value: "gw" },
    { label: "Guyana", value: "gy" },
    { label: "Hong Kong", value: "hk" },
    { label: "Honduras", value: "hn" },
    { label: "Croatia", value: "hr" },
    { label: "Haiti", value: "ht" },
    { label: "Hungary", value: "hu" },
    { label: "Indonesia", value: "id" },
    { label: "Ireland", value: "ie" },
    { label: "Israel", value: "il" },
    { label: "Isle of Man", value: "im" },
    { label: "India", value: "in" },
    { label: "British Indian Ocean Territory", value: "io" },
    { label: "Iraq", value: "iq" },
    { label: "Iran", value: "ir" },
    { label: "Iceland", value: "is" },
    { label: "Italy", value: "it" },
    { label: "Jersey", value: "je" },
    { label: "Jamaica", value: "jm" },
    { label: "Jordan", value: "jo" },
    { label: "Japan", value: "jp" },
    { label: "Kenya", value: "ke" },
    { label: "Kyrgyz Republic (Kyrgyzstan)", value: "kg" },
    { label: "Cambodia", value: "kh" },
    { label: "Kiribati", value: "ki" },
    { label: "Comoros", value: "km" },
    { label: "Saint Kitts & Nevis Anguilla", value: "kn" },
    { label: "North Korea", value: "kp" },
    { label: "South Korea", value: "kr" },
    { label: "Kuwait", value: "kw" },
    { label: "Cayman Islands", value: "ky" },
    { label: "Kazakhstan", value: "kz" },
    { label: "Laos", value: "la" },
    { label: "Lebanon", value: "lb" },
    { label: "Saint Lucia", value: "lc" },
    { label: "Liechtenstein", value: "li" },
    { label: "Sri Lanka", value: "lk" },
    { label: "Liberia", value: "lr" },
    { label: "Lesotho", value: "ls" },
    { label: "Lithuania", value: "lt" },
    { label: "Luxembourg", value: "lu" },
    { label: "Latvia", value: "lv" },
    { label: "Libya", value: "ly" },
    { label: "Morocco", value: "ma" },
    { label: "Monaco", value: "mc" },
    { label: "Moldavia (Moldova)", value: "md" },
    { label: "Montenegro", value: "me" },
    { label: "Madagascar", value: "mg" },
    { label: "Marshall Islands", value: "mh" },
    { label: "Macedonia", value: "mk" },
    { label: "Mali", value: "ml" },
    { label: "Myanmar", value: "mm" },
    { label: "Mongolia", value: "mn" },
    { label: "Macau", value: "mo" },
    { label: "Northern Mariana Islands", value: "mp" },
    { label: "Martinique (French)", value: "mq" },
    { label: "Mauritania", value: "mr" },
    { label: "Montserrat", value: "ms" },
    { label: "Malta", value: "mt" },
    { label: "Mauritius", value: "mu" },
    { label: "Maldives", value: "mv" },
    { label: "Malawi", value: "mw" },
    { label: "Mexico", value: "mx" },
    { label: "Malaysia", value: "my" },
    { label: "Mozambique", value: "mz" },
    { label: "Namibia", value: "na" },
    { label: "New Caledonia (French)", value: "nc" },
    { label: "Niger", value: "ne" },
    { label: "Norfolk Island", value: "nf" },
    { label: "Nigeria", value: "ng" },
    { label: "Nicaragua", value: "ni" },
    { label: "Netherlands", value: "nl" },
    { label: "Norway", value: "no" },
    { label: "Nepal", value: "np" },
    { label: "Nauru", value: "nr" },
    { label: "Niue", value: "nu" },
    { label: "New Zealand", value: "nz" },
    { label: "Oman", value: "om" },
    { label: "Panama", value: "pa" },
    { label: "Peru", value: "pe" },
    { label: "Polynesia (French)", value: "pf" },
    { label: "Papua New Guinea", value: "pg" },
    { label: "Philippines", value: "ph" },
    { label: "Pakistan", value: "pk" },
    { label: "Poland", value: "pl" },
    { label: "Saint Pierre and Miquelon", value: "pm" },
    { label: "Pitcairn Island", value: "pn" },
    { label: "Puerto Rico", value: "pr" },
    { label: "Palestine", value: "ps" },
    { label: "Portugal", value: "pt" },
    { label: "Palau", value: "pw" },
    { label: "Paraguay", value: "py" },
    { label: "Qatar", value: "qa" },
    { label: "Reunion (French)", value: "re" },
    { label: "Romania", value: "ro" },
    { label: "Serbia", value: "rs" },
    { label: "Russian Federation", value: "ru" },
    { label: "Rwanda", value: "rw" },
    { label: "Saudi Arabia", value: "sa" },
    { label: "Solomon Islands", value: "sb" },
    { label: "Seychelles", value: "sc" },
    { label: "Sudan", value: "sd" },
    { label: "Sweden", value: "se" },
    { label: "Singapore", value: "sg" },
    { label: "Saint Helena", value: "sh" },
    { label: "Slovenia", value: "si" },
    { label: "Svalbard and Jan Mayen Islands", value: "sj" },
    { label: "Slovak Republic", value: "sk" },
    { label: "Sierra Leone", value: "sl" },
    { label: "San Marino", value: "sm" },
    { label: "Senegal", value: "sn" },
    { label: "Somalia", value: "so" },
    { label: "Suriname", value: "sr" },
    { label: "Saint Tome (Sao Tome) and Principe", value: "st" },
    { label: "El Salvador", value: "sv" },
    { label: "Syria", value: "sy" },
    { label: "Swaziland", value: "sz" },
    { label: "Turks and Caicos Islands", value: "tc" },
    { label: "Chad", value: "td" },
    { label: "Togo", value: "tg" },
    { label: "Thailand", value: "th" },
    { label: "Tadjikistan", value: "tj" },
    { label: "Tokelau", value: "tk" },
    { label: "East Timor", value: "tl" },
    { label: "Turkmenistan", value: "tm" },
    { label: "Tunisia", value: "tn" },
    { label: "Tonga", value: "to" },
    { label: "Turkey", value: "tr" },
    { label: "Trinidad and Tobago", value: "tt" },
    { label: "Tuvalu", value: "tv" },
    { label: "Taiwan", value: "tw" },
    { label: "Tanzania", value: "tz" },
    { label: "Ukraine", value: "ua" },
    { label: "Uganda", value: "ug" },
    { label: "United States", value: "us" },
    { label: "Uruguay", value: "uy" },
    { label: "Uzbekistan", value: "uz" },
    { label: "Holy See (Vatican City State)", value: "va" },
    { label: "Saint Vincent & Grenadines", value: "vc" },
    { label: "Venezuela", value: "ve" },
    { label: "Virgin Islands (British)", value: "vg" },
    { label: "Virgin Islands (USA)", value: "vi" },
    { label: "Vietnam", value: "vn" },
    { label: "Vanuatu", value: "vu" },
    { label: "Wallis and Futuna Islands", value: "wf" },
    { label: "Samoa", value: "ws" },
    { label: "Kosovo", value: "xk" },
    { label: "Yemen", value: "ye" },
    { label: "Mayotte", value: "yt" },
    { label: "South Africa", value: "za" },
    { label: "Zambia", value: "zm" },
    { label: "Zimbabwe", value: "zw" },
  ],
};
